import React from 'react'
import "./Loader1.scss";

const Loader1 = () => {
  return (
    <div>
      <div className="loader1" >
<p>ghjk</p>
      </div>
    </div>
  )
}

export default Loader1
